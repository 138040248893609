import Snabbdom from 'snabbdom-pragma'
import cn from 'classnames'
import { interval } from 'xsrx'
import { delay, map, first, takeUntil } from 'xsrx/operators'

import logo from '../../static/images/logo-white.svg'
import styles from './styles.module.scss'

function Splash({ DOM, STORAGE }) {
  const preload$ = interval(10) |> map(i => i / 10) |> delay(10)

  const onTransitionEnd$ =
    DOM.select(`.${styles.splash}`).events('transitionend')
    |> first(({ propertyName }) => propertyName === 'opacity')

  return {
    DOM:
      preload$
      |> map(progress => (
        <div className={styles.splash} key="splash">
          <span style={{ opacity: 0.5 }}>
            <div
              style={{ backgroundImage: `url(${logo})` }}
              innerHTML={logo}
              className={cn(
                styles.logo,
                styles.grayscale,
                progress >= 1 && styles.hidden,
              )}
            />
          </span>
          <div
            className={cn(styles.logo, progress >= 1 && styles.hidden)}
            innerHTML={logo}
            style={{
              clipPath: `inset(${100 - progress * 100}% 0 0 0)`,
            }}
          />
        </div>
      ))
      |> takeUntil(onTransitionEnd$),

    auth$:
      STORAGE.getItem('auth')
      |> first()
      |> map(auth => ({ verify }) => verify(auth)),
  }
}

export default Splash
