import { Observable } from './Observable';
import { Subscription } from './Subscription';
export function merge() {
  for (var _len = arguments.length, observables = new Array(_len), _key = 0; _key < _len; _key++) {
    observables[_key] = arguments[_key];
  }

  if (observables.length === 1) {
    // TODO: check if observable
    return observables[0];
  }

  return new Observable(listener => {
    var subs = observables.map(observable => observable.subscribe(listener));
    return new Subscription(() => {
      subs.forEach(sub => {
        sub.unsubscribe();
      });
    });
  });
}