import * as COLORS from './colors'

const COMMON = {
  '--neutral': COLORS.NEUTRAL.BASE,
  '--neutral-contrast': COLORS.NEUTRAL.CONTRAST,
  '--neutral-light': COLORS.NEUTRAL_LIGHT.BASE,
  '--neutral-light-contrast': COLORS.NEUTRAL_LIGHT.CONTRAST,
  '--neutral-lighter': COLORS.NEUTRAL_LIGHTER.BASE,
  '--neutral-lighter-contrast': COLORS.NEUTRAL_LIGHTER.CONTRAST,
  '--neutral-lightest': COLORS.NEUTRAL_LIGHTEST.BASE,
  '--neutral-lightest-contrast': COLORS.NEUTRAL_LIGHTEST.CONTRAST,
  '--neutral-dark': COLORS.NEUTRAL_DARK.BASE,
  '--neutral-dark-contrast': COLORS.NEUTRAL_DARK.CONTRAST,
  '--neutral-darker': COLORS.NEUTRAL_DARKER.BASE,
  '--neutral-darker-contrast': COLORS.NEUTRAL_DARKER.CONTRAST,
  '--neutral-darkest': COLORS.NEUTRAL_DARKEST.BASE,
  '--neutral-darkest-contrast': COLORS.NEUTRAL_DARKEST.CONTRAST,

  '--accent': COLORS.ACCENT.BASE,
  '--accent-contrast': COLORS.ACCENT.CONTRAST,

  '--danger': COLORS.RED.BASE,
  '--danger-contrast': COLORS.RED.CONTRAST,

  '--warning': COLORS.ORANGE.BASE,
  '--warning-contrast': COLORS.ORANGE.CONTRAST,

  '--info': COLORS.BLUE.BASE,
  '--info-contrast': COLORS.BLUE.CONTRAST,

  '--success': COLORS.GREEN.BASE,
  '--success-contrast': COLORS.GREEN.CONTRAST,

  '--badge': COLORS.MAGENTA.BASE,
  '--badge-contrast': COLORS.MAGENTA.CONTRAST,

  '--code': COLORS.CODE.BASE,
  '--code-contrast': COLORS.CODE.CONTRAST,

  '--opacity-full': 1,
  '--opacity-high': 0.87,
  '--opacity-medium': 0.6,
  '--opacity-disabled': 0.38,
  '--opacity-hint': 0.24,
  '--opacity-divider': 0.12,

  '--elevation-none': '0 0',
  '--elevation-0': '0 1px 2px rgba(0, 0, 0, 0.14)',
  '--elevation-1': '0 2px 4px rgba(0, 0, 0, 0.18)',
  '--elevation-2': '0 4px 8px rgba(0, 0, 0, 0.16)',
  '--elevation-3': '0 8px 16px rgba(0, 0, 0, 0.14)',
  '--elevation-4': '0 16px 24px rgba(0, 0, 0, 0.12)',
  '--elevation-5': '0 24px 32px rgba(0, 0, 0, 0.1)',

  '--main-font':
    '"Palanquin", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Avenir", "PingFang SC", "Helvetica Neue", Helvetica',
  '--mono-font':
    '"Dank Mono", Menlo, Monaco, Consolas, "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Lucida Console", "Liberation Mono", source-code-pro, "Courier New", monospace, serif',
}

export const LIGHT = {
  '--background': COLORS.BACKGROUND.BASE,
  '--background-contrast': COLORS.BACKGROUND.CONTRAST,

  '--surface': COLORS.FOREGROUND.BASE,
  '--surface-contrast': COLORS.FOREGROUND.CONTRAST,

  '--foreground': COLORS.NEUTRAL_DARKEST.CONTRAST,
  '--foreground-contrast': COLORS.NEUTRAL_DARKEST.BASE,

  '--primary': COLORS.BLACK.BASE,
  '--primary-contrast': COLORS.BLACK.CONTRAST,

  '--secondary': COLORS.WHITE.BASE,
  '--secondary-contrast': COLORS.WHITE.CONTRAST,

  ...COMMON,
}

export const DARK = {
  '--background': COLORS.NEUTRAL_DARKEST.BASE,
  '--background-contrast': COLORS.NEUTRAL_DARKEST.CONTRAST,

  '--surface': COLORS.NEUTRAL_DARKER.BASE,
  '--surface-contrast': COLORS.NEUTRAL_DARKER.CONTRAST,

  '--foreground': COLORS.WHITE.CONTRAST,
  '--foreground-contrast': COLORS.WHITE.BASE,

  '--primary': COLORS.WHITE.BASE,
  '--primary-contrast': COLORS.WHITE.CONTRAST,

  '--secondary': COLORS.BLACK.BASE,
  '--secondary-contrast': COLORS.BLACK.CONTRAST,

  ...COMMON,
}
