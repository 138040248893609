import { from, merge } from 'xsrx'
import { scan, map } from 'xsrx/operators'

function loadImage(src) {
  const p = new Promise((resolve, reject) => {
    const image = new Image()

    image.onload = resolve
    image.onerror = image.onabort = reject

    image.src = src
  })

  return from(p)
}

export default function preloadImages(srcs) {
  let preload$

  const p = new Promise(resolve => {
    preload$ =
      merge(...srcs.map(loadImage))
      |> map(() => 1)
      |> scan((acc, x) => acc + x, 0)
      |> map(count => count / srcs.length)

    preload$.subscribe({
      complete: resolve,
    })
  })

  return { promise: p, stream: preload$ }
}
