export const WHITE = { BASE: '#ffffff', CONTRAST: '#000000' }
export const BLACK = { BASE: '#000000', CONTRAST: '#ffffff' }

export const BACKGROUND = { BASE: '#F5F5F5', CONTRAST: '#000000' }
export const FOREGROUND = { BASE: '#FFFFFF', CONTRAST: '#000000' }

export const NEUTRAL_LIGHT = { BASE: '#DEDEDE', CONTRAST: '#000000' }
export const NEUTRAL_LIGHTER = { BASE: '#E6E6E6', CONTRAST: '#000000' }
export const NEUTRAL_LIGHTEST = { BASE: '#EDEDED', CONTRAST: '#000000' }

export const NEUTRAL = { BASE: '#9E9E9E', CONTRAST: '#000000' }

export const NEUTRAL_DARK = { BASE: '#757575', CONTRAST: '#ffffff' }
export const NEUTRAL_DARKER = { BASE: '#343434', CONTRAST: '#ffffff' }
export const NEUTRAL_DARKEST = { BASE: '#212121', CONTRAST: '#ffffff' }

export const ACCENT = { BASE: '#228DD8', CONTRAST: '#ffffff' }

export const BLUE = { BASE: '#0076FF', CONTRAST: '#ffffff' }
export const RED = { BASE: '#FF0000', CONTRAST: '#ffffff' }
export const MAGENTA = { BASE: '#FF0080', CONTRAST: '#ffffff' }
export const ORANGE = { BASE: '#F5A623', CONTRAST: '#000000' }
export const YELLOW = { BASE: '#F8E71C', CONTRAST: '#000000' }
export const GREEN = { BASE: '#50E3C2', CONTRAST: '#ffffff' }

export const CODE = { BASE: '#d400ff', CONTRAST: '#ffffff' }
