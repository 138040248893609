function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import { Subject } from './Subject';
import { merge } from './merge';
var defaultOptions = {
  ignoreEmptyPatches: false
};
export function createMiddleware(epics, options, Producer) {
  if (epics === void 0) {
    epics = [];
  }

  if (options === void 0) {
    options = {
      ignoreEmptyPatches: false
    };
  }

  if (!Producer) {
    Producer = typeof options === 'function' ? options : Subject;
  }

  var opts = options !== null && typeof options === 'object' ? _extends({}, defaultOptions, {}, options) : defaultOptions;
  var patch$ = new Producer();
  var state$ = new Producer();
  var prevState$ = new Producer();
  var epics$ = epics.map(epic => epic(patch$, state$, prevState$));
  var update$ = merge(...epics$); // subscribe

  var instance = {};
  update$.subscribe({
    next: cb => instance.update == null ? void 0 : instance.update(cb)
  });
  return function middleware(_ref, state, prevState) {
    var {
      patches
    } = _ref;
    instance = this;
    if (!!opts.ignoreEmptyPatches && patches.length === 0) return; // notify

    for (var patch of patches) {
      patch$.next(patch);
    }

    state$.next(state);
    prevState$.next(prevState);
  };
}