import { interval, merge } from 'xsrx'
import { buffer, pluck, map, filter } from 'xsrx/operators'

import { ofType } from '../../operators'

export default function model(socket$) {
  const init$ =
    socket$
    |> ofType('INIT')
    |> pluck('data')
    |> map(data => draft => {
      const { rooms, tables, ...config } = data
      draft.config = config
      draft.tables = tables
      draft.rooms = Object.entries(rooms).reduce((acc, [rid, room]) => {
        room.roomName =
          room.roomName ??
          room.tables.map(tid => tables?.[tid]?.tableName).join(' | ')

        acc[rid] = room
        return acc
      }, {})
    })

  const roster$ =
    socket$
    |> ofType('ROSTER')
    |> map(({ data: room }) => draft => {
      const existing = draft.rooms[room.rid]
      existing && (draft.rooms[room.rid].roster = room.roster)
    })

  const message$ =
    socket$
    |> ofType('CHAT')
    |> pluck('data')
    |> buffer(interval(1000))
    |> filter(messages => messages.length > 0)
    |> map(bufferedMessages => draft => {
      const updates = bufferedMessages.reduce((acc, m) => {
        const existing = draft.rooms[m.rid]
        const fraction = (acc[m.rid] = acc[m.rid] ?? {
          unread: [],
          ...existing,
        })
        fraction.latestMessage = m

        if (draft.lens.room !== m.rid) {
          fraction.unread.push(m._id)
          draft.unread.rooms += 1
        }
        return acc
      }, {})

      for (const [rid, update] of Object.entries(updates)) {
        update.latestMessage &&
          (draft.rooms[rid].latestMessage = update.latestMessage)
        draft.rooms[rid].unread = update.unread
      }
    })

  return merge(init$, roster$, message$)
}
