/* eslint-disable camelcase */
import xs from 'xstream'
import { adapt } from '@cycle/run/lib/adapt'
import qs from 'querystring'

const SERVER_URL = process.env.SERVER_URL

const GOOGLE_OAUTH2_AUTH_BASE_URL_ =
  'https://accounts.google.com/o/oauth2/v2/auth'

function generateAuthUrl(config) {
  config.response_type = 'code'
  config.access_type = 'offline'
  config.include_granted_scopes = true
  config.state = 'state_parameter_passthrough_value'
  config.client_id = config.client_id || process.env.GOOGLE_CLIENT_ID
  config.redirect_uri =
    config.redirect_uri ||
    process.env.GOOGLE_REDIRECT_URI ||
    `${window.location.origin}/oauth2callback`
  // Allow scopes to be passed either as array or a string
  if (config.scope instanceof Array) {
    config.scope = config.scope.join(' ')
  }
  config.prompt = 'consent'
  return GOOGLE_OAUTH2_AUTH_BASE_URL_ + '?' + qs.stringify(config)
}

export default function makeAuthDriver(config) {
  const AUTH_URL = generateAuthUrl(config)
  const source = xs.createWithMemory()
  let _auth = null

  async function receiveMessage(event) {
    if (event.origin !== window.location.origin) return
    const { code, pathname } = event?.data

    if (pathname !== '/oauth2callback') return

    if (code) {
      const res = await fetch(`${SERVER_URL}/auth?code=${code}`)
      const auth = await res.json()
      _auth = auth
      source._n(auth)
    }
  }
  window.addEventListener('message', receiveMessage, false)

  return function AuthDriver(sink) {
    const auth = {
      signIn: async function signIn() {
        window.open(AUTH_URL)
      },
      verify: async function verify(lsa) {
        try {
          const { refresh_token } = lsa
          const auth = await fetch(`${SERVER_URL}/auth`, {
            headers: {
              Authorization: `Bearer ${refresh_token}`,
            },
          }).then(res => res.json())
          source._n(auth)
          _auth = auth
        } catch (error) {
          source._n({})
          _auth = {}
        }
      },
      signOut: async function signOut() {
        await fetch(`${SERVER_URL}/auth`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${_auth.refresh_token}`,
          },
        }).then(res => res.json())
        source._n(null)
        _auth = null
      },
    }

    sink.subscribe({ next: cb => cb(auth) })

    return adapt(source)
  }
}
