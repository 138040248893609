// import { enablePatches } from 'immer'
import create from 'immerx'
import { createMiddleware } from '@immer/middleware'

import xs from 'xstream'

import { rootEpic } from '../../epics'
// enablePatches()

function isolateSource(source, scope) {
  const state$ = source.select(scope)

  const stream = xs
    .create({
      start(listener) {
        this.sub = state$.subscribe(listener)
      },
      stop() {
        this.sub?.unsubscribe?.()
      },
    })
    .remember()

  return {
    stream,
    isolateSource: (source, lens) =>
      lens != null ? isolateSource(state$, lens) : state$,
  }
}

export default function makeStateDriver(initialState = null) {
  const state$ = create(initialState)
  window.__IMMER_DEVTOOLS_STATE$__ = state$
  // const ifrm = document.createElement('iframe')
  // ifrm.setAttribute('id', 'ifrm')
  // ifrm.setAttribute('src', 'devtools/devtools.html')
  // ifrm.setAttribute(
  //   'style',
  //   'position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;',
  // )
  // document.body.appendChild(ifrm)

  return function stateDriver(sink) {
    sink.subscribe({
      next: patch => state$.update(patch),
    })

    const stream = xs.createWithMemory({
      start(listener) {
        this.sub = state$.subscribe(listener)
      },
      stop() {
        this.sub?.unsubscribe?.()
      },
    })

    state$.applyMiddleware(
      createMiddleware([rootEpic], { ignoreEmptyPatches: true }),
    )
    return {
      stream,
      isolateSource: (source, lens) =>
        lens != null ? isolateSource(state$, lens) : state$,
    }
  }
}

export { makeCollection } from './Collection'
