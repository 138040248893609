import { from } from 'xsrx'
import { filter, map } from 'xsrx/operators'

export function rootEpic(patch$) {
  return (
    from(patch$)
    |> filter(
      patch =>
        ['add', 'replace'].includes(patch.op) &&
        /^lens\.room/.test(patch.path.join('.')),
    )
    |> map(patch => draft => {
      const currRoom = draft.rooms[patch.value]

      draft.unread.rooms -= currRoom.unread?.length ?? 0
      currRoom.unread = []
    })
  )
}
