import { map } from 'xsrx/operators'

// applyTo :: Stream s => a -> s (a -> b) -> s b
export function applyTo(data) {
  return function applyToOperator(source) {
    return (
      source
      |> map(fn => {
        if (typeof fn !== 'function') {
          throw new Error(`'applyTo' operator requires a stream of functions`)
        }
        return fn(data)
      })
    )
  }
}
