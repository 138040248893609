export const INITIAL_STATE = {
  audio: {
    muted: true,
  },
  route: {
    active: { name: 'rooms', title: 'Rooms' },
  },
  lens: {},
  filters: {},
  config: {},
  rooms: {},
  tables: {},
  support: {},
  unread: {
    rooms: 0,
    support: 0,
  },
}
