import { adapt } from '@cycle/run/lib/adapt'
import xs from 'xstream'

export default function makeWebSocketDriver() {
  const source = xs.create()
  let ws$
  let client
  let constants = {}

  const handler = {
    connect(urlConfigOrSource) {
      ws$ = xs.create({
        start: function(listener) {
          client = new WebSocket(urlConfigOrSource)
          client.onerror = err => {
            listener.error(err)
          }
          client.onmessage = ({ data }) => {
            const parsed = JSON.parse(data)
            if (parsed.type === 'INIT') constants = parsed.data.constants

            listener.next(parsed)
          }
          client.onclose = () => {
            listener.complete()
          }
        },
        stop: function() {
          client.close()
        },
      })

      source.imitate(ws$)
    },
    disconnect() {
      client?.close?.()
      ws$?.complete?.()
      client = null
    },
    send(message) {
      client?.send?.(
        typeof message === 'string' ? message : JSON.stringify(message),
      )
    },
    get TYPES() {
      return constants.TYPES
    },
  }

  return function webSocketDriver(sink) {
    sink.subscribe({
      next: f => f(handler),
    })

    return adapt(source)
  }
}
