import { adapt } from '@cycle/run/lib/adapt'
import { from } from 'xsrx'
import { map, startWith, filter } from 'xsrx/operators'

function parse(string) {
  if (string === 'undefined') return

  try {
    return JSON.parse(string)
  } catch (error) {
    return string
  }
}

export default function makeStorageDriver(namespace) {
  return function storageDriver(sink) {
    function getStorageItem(key) {
      return parse(localStorage.getItem(`[${namespace}] ${key}`))
    }

    function writeToStorage(request) {
      const { action = 'setItem', key, value } = request
      const next =
        typeof value === 'function' ? value(getStorageItem(key)) : value
      localStorage[action](`[${namespace}] ${key}`, JSON.stringify(next))
      return next
    }

    function readFromStorage(key) {
      return (
        from(sink)
        |> filter(req => req.key === key)
        |> map(req => {
          const next =
            typeof req.value === 'function'
              ? req.value(getStorageItem(key))
              : req.value
          return next
        })
        |> startWith(getStorageItem(key))
      )
    }

    const source = {
      getItem(key) {
        return adapt(readFromStorage(key))
      },
    }

    sink.subscribe({
      next: request => {
        writeToStorage(request)
      },
    })

    return source
  }
}
