import { map } from 'xsrx/operators'

// mapProps :: Stream s => { (* -> *) } -> s Object -> s Object
export function mapProps(spec) {
  return function evolveOperator(source) {
    return (
      source
      |> map(obj =>
        Object.entries(obj).reduce((acc, [key, value]) => {
          acc[key] =
            key in spec && spec.hasOwnProperty(key) ? value |> spec[key] : value
          return acc
        }, obj),
      )
    )
  }
}
