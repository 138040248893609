import { Stream } from 'xstream'
import { run } from '@cycle/run'
import { makeDOMDriver } from '@cycle/dom'
import { makeHTTPDriver } from '@cycle/http'
import { concat, merge, noop, of, EMPTY } from 'xsrx'
import { map, distinctUntilChanged } from 'xsrx/operators'
import qs from 'querystring'

import makeStorageDriver from './drivers/storage'
import makeAuthDriver from './drivers/auth'
import makeWebSocketDriver from './drivers/socket'
import makeEffectsDriver from './drivers/effects'
import makeImmerDriver from './drivers/state'

import preloadImages from './functions/preloadImages'
import * as THEMES from './constants/themes'
import { NAMESPACE } from './constants/storage'
import { INITIAL_STATE } from './constants/initialState'

import App from './views/App'
import Splash from './views/Splash'
import './styles.scss'

// if (process.env.NODE_ENV === 'development') {
//   const ILS = new WeakSet()
//   window.__ILS__ = ILS

//   const _add = Stream.prototype._add
//   const _remove = Stream.prototype._remove
//   const _stopNow = Stream.prototype._stopNow

//   function spyStream(Stream) {
//     Stream.prototype._stopNow = function spyStopNow() {
//       _stopNow.call(this)
//     }

//     Stream.prototype._remove = function spyRemove(l) {
//       ILS.delete(l)
//       _remove.call(this, l)
//     }
//     Stream.prototype._add = function spyAdd(l) {
//       ILS.add(l)
//       _add.call(this, l)
//     }
//   }
//   spyStream(Stream)
// }

function main(sources) {
  // doesn't load without this
  // https://github.com/cyclejs/cyclejs/issues/938
  sources.state.stream.subscribe({
    next: v => {
      window.__state__ = v
    },
  })
  const auth$ = sources.auth$ |> distinctUntilChanged()

  const authReducer$ =
    auth$
    |> map(auth => draft => {
      if (auth) {
        draft.auth = auth
        draft.me = auth?.user
        if (draft.route.previous) {
          draft.route.active = draft.route.previous
          draft.route.previous = { name: 'auth', title: 'Auth' }
        }
      } else {
        delete draft.auth
        delete draft.me
        draft.route.previous = draft.route.active
        draft.route.active = { name: 'auth', title: 'Auth' }
      }
    })

  const storageUpdate$ =
    auth$
    |> map(auth => ({
      action: auth ? 'setItem' : 'removeItem',
      key: 'auth',
      value: auth,
    }))

  const { DOM: splashDOM$, auth$: splashAuth$ } = Splash(sources)
  const {
    DOM: appDOM$,
    state: appReducer$ = EMPTY,
    auth$: appAuth$ = of(noop),
    ...appSinks
  } = App(sources)

  return {
    state: merge(authReducer$, appReducer$),
    DOM: concat(splashDOM$, appDOM$),
    auth$: merge(splashAuth$, appAuth$),
    ...appSinks,
    STORAGE: storageUpdate$,
  }
}

const drivers = {
  DOM: makeDOMDriver('#root'),
  HTTP: makeHTTPDriver(),
  STORAGE: makeStorageDriver(NAMESPACE),
  socket$: makeWebSocketDriver(),
  auth$: makeAuthDriver({
    client_id:
      '226360826705-b6pl4v50d11f8qgac6o5cm4fa5723c5t.apps.googleusercontent.com',
    scope: [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
    ],
  }),
  effects$: makeEffectsDriver(),
  state: makeImmerDriver(INITIAL_STATE),
}

if (window.location.pathname === '/oauth2callback') {
  const { code, error } = qs.parse(window.location.search.substr(1))
  if (!error && code && window.opener) {
    window.opener?.postMessage?.(
      { code, pathname: window.location.pathname },
      window.location.origin,
    )
  }
  window?.close()
} else {
  render()
}

async function render() {
  await preloadImages(['static/images/logo-white.svg']).promise

  // Setup css variables
  const html = document.getElementsByTagName('html')[0]
  for (const [key, value] of Object.entries({
    ...THEMES.LIGHT,
  })) {
    html.style.setProperty(key, value)
  }

  run(main, drivers)
}
