import { EMPTY } from 'xsrx'
import { switchMap, share } from 'xsrx/operators'

// extractSinks :: Stream s =>  [ String ] -> s { s } -> { s }
export function extractSinks(selector, tag) {
  return function extractSinksOperator(source) {
    const sinks$ = source |> share()

    if (Array.isArray(selector)) {
      return selector.reduce((sinks, sel) => {
        sinks[sel] = extractSinks(sel)(sinks$)
        return sinks
      }, {})
    }

    return sinks$ |> switchMap(sinks => sinks[selector] || EMPTY)
  }
}
