import { filter } from 'xsrx/operators'

// ofType :: Stream s => ( [ String ] | String, ..., String ) -> s Object -> s Object
export function ofType() {
  const types = [...arguments].flat()

  return function ofTypeOperator(source) {
    return source |> filter(data => types.includes(data?.type))
  }
}
