import Snabbdom from 'snabbdom-pragma'
import { from, merge } from 'xsrx'
import { map, distinctUntilChanged, switchMap, pluck } from 'xsrx/operators'

import { debug, extractSinks, mapProps, applyTo } from '../../operators'

import model from './model'

import styles from './styles.module.scss'

function App(sources) {
  const sinks$ =
    sources.auth$
    |> distinctUntilChanged()
    |> switchMap(
      auth =>
        from(
          auth ? import('../../routes/Router') : import('../../routes/Auth'),
        ) |> pluck('default'),
    )
    |> applyTo(sources)

  const { DOM: routeDOM$, state: routeReducer$, ...routeSinks } =
    sinks$
    |> mapProps({
      DOM: map(vdom => <div className={styles.app}>{vdom}</div>),
    })
    |> extractSinks(['DOM', 'state', 'auth$', 'socket$', 'HTTP', 'effects$'])

  const socketReducer$ = model(sources.socket$)

  return {
    DOM: routeDOM$, // |> debug('dom'),
    state: merge(routeReducer$, socketReducer$),
    ...routeSinks,
  }
}

export default App
